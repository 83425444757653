import './App.css';
import React, {useState} from "react";
import Counter from "./Counter";
import { Link, Element } from 'react-scroll';
import GoogleMapWithIframe from "./GoogleMap";
import GoogleMapWithIframe2 from "./GoogleMap2";
import Navbar from "./Navbar";

function App() {

    const [content, setContent] = useState('home');

    const handleNavClick = (page) => {
        setContent(page);
        console.log(content)
    };

  return (
    <div className="App">
        <div className="container">
            <header className="App-header">
                <div className="header-div">
                    <div className="menu">
                        <div className="logo">

                        <Link onClick={() => handleNavClick('home')}>
                            <h1 className="logo-name">
                                 Emili & Robi esküvője
                            </h1></Link>
                        </div>
                    <div className="nav">
                    <nav>
                                <ul className="navbar">
                                    <li><Link onClick={() => handleNavClick('home')}>Főoldal</Link></li>
                                    { content === 'home' && (<li><Link to="section1" smooth={true} duration={500}>Mikor és Hol</Link></li>)}
                                    <li><Link onClick={() => handleNavClick('map')}>Térkép</Link></li>
                                    { content === 'home' && (<li><Link to="section2" smooth={true} duration={500}>Képek</Link></li>)}
                                </ul>
                            </nav>
                        </div>
                        <Navbar onNavClick={handleNavClick} content={content}/>
                    </div>
                </div>
            </header>
            {content === 'home' && (
                <main className="App-main">
                    <div className="header-pic">
                        <img className="header-img" src="header2.jpg" alt="header-logo" width="80%" height="80%"/>
                    </div>
                    <div className="counter">
                        <Counter targetDate={new Date('2024-07-06T00:00:00+02:00')}/>
                    </div>
                    <div className="quote">
                        <p className="quote-text">Sorsunk egybeforr, együtt megyünk tovább,</p>
                        <p className="quote-text">Az élet viharában Te vigyázol reám.</p>
                        <p className="quote-text">Köszönöm, hogy szeretsz, s hogy hiszel nekem,</p>
                        <p className="quote-text">S hogy megosztod az életed velem.</p>
                        <p></p>
                        <p className="quote-text quote-text-vm">(Vörösmarty Mihály)</p>
                    </div>
                    <div className="header-pic2">
                        <img className="header-img-brno" src="timeline5.png" alt="header-logo"/>
                    </div>
                    <Element name="section1">
                        <div className="when">
                            <div className="title">
                                <h1 className="counter-title">Ünnepeljük együtt ezt a napot!</h1>
                                <div className="title-line"></div>
                            </div>
                        </div>
                    </Element>
                    <div className="where">
                        <div className="text">
                            <h2 className="when-title">Mikor</h2>
                            <p>2024. július 6., szombat</p>
                            <p>Gyülekező: 14:30 - 15:00</p>
                            <p>Szertarás: 16:00</p>
                            <p>Lakodalom: 17:30</p>
                        </div>
                        <div className="title-line-mid"></div>
                        <div className="map-text">
                            <h2 className="when-title">Hol</h2>
                            <p>Gyülekező: martosi kultúrházban</p>
                            <p>(templommal szemben)</p>
                            <p>Szertartás: martosi református templom</p>
                            <p>Lakodalom: <a className="dio-link" href="http://www.staryorech.sk/" target="_blank"
                                             rel="noopener noreferrer">Vén Diófa Étterem</a></p>
                            <li><Link className="map-link" Link onClick={() => handleNavClick('map')}>Térkép</Link></li>
                        </div>
                    </div>
                    <div className="pictures">
                        <div className="row">
                            <div className="column">
                                <img src="kamzik3.jpg"/>
                                <img src="banska.jpg"/>
                                <img src="kamzik.jpg"/>
                            </div>
                            <div className="column">
                                <img src="brno.jpg"/>
                                <img src="ring.jpg"/>
                                <img src="wedding.jpg"/>
                            </div>
                            <div className="column">
                                <img src="bday.jpg"/>
                                <img src="flower.jpg"/>
                            </div>
                        </div>
                    </div>
                    <div className="pictures-mobile">
                        <img src="brno.jpg" alt="brno" width="80%" height="80%"/>
                        <img src="kamzik3.jpg" alt="banska" width="80%" height="80%"/>
                        <img src="ring.jpg" alt="ring" width="80%" height="80%"/>
                        <img src="bday.jpg" alt="bday" width="80%" height="80%"/>
                        <img src="wedding.jpg" alt="wedding" width="80%" height="80%"/>
                        <img src="banska.jpg" alt="banska" width="80%" height="80%"/>
                        <img src="flower.jpg" alt="ring" width="80%" height="80%"/>
                        <img src="kamzik.jpg" alt="banska" width="80%" height="80%"/>

                    </div>
                    <div className="gift">
                        <h1 className="counter-title">Ajándék? </h1>
                        <div className="title-line"></div>
                        <p className="counter-text">Mindennek örülünk, ami egy borítékba belefér!</p>
                        <img src="moneycry.gif" className="moneyCry" alt="moneycry"/>
                    </div>
                    <Element name="section2">
                        <div className="qr">
                            <div className="qrtext">
                                <h1 className="counter-title">Képek</h1>
                                <div className="title-line"></div>
                                <h1 className="counter-text">Osszd meg velünk az esküvőn készített képeidet:</h1>
                            </div>
                            <div className="qrpic">
                                <p className="counter-text">(Kattints a képre vagy scanneld be)</p>
                                <a className="dio-link"
                                   href="https://drive.google.com/drive/folders/1-bTObVl70XfaifperKUTekpUbM3oJukv?usp=sharing"
                                   target="_blank"
                                   rel="noopener noreferrer"><img src="wedding_qr_code.png" alt="qr" width="25%"
                                                                  height="25%"/></a>
                            </div>
                        </div>
                    </Element>
                    <div className="feedback">
                        <h1 className="feedback-title">Kérjük, jelenlétedet jelezd legkésőbb június
                            21-éig.</h1>
                    </div>
                    <div className="bottom">
                        <img src="bottom.png" className="bottompng" alt="bottom"/>
                    </div>
                </main>
            )}
            {content !== 'home' && (
                <main className="App-main">
                    <h1 className="counter-title-map">Mikor és hol</h1>
                    <div className="title-line"></div>
                    <div className="map-content">
                        <div className="where-map">
                            <div className="map-text">
                                <h2 className="when-title">Mikor</h2>
                                <p>2024. július 6., szombat</p>
                                <p>Gyülekező: 14:30 - 15:00</p>
                                <p>Szertarás: 16:00</p>
                                <p>Lakodalom: 17:30</p>
                            </div>
                            <div className="title-line-mid"></div>
                            <div className="map-text">
                                <h2 className="when-title">Hol</h2>
                                <p>Gyülekező: martosi kultúrházban</p>
                                <p>(templommal szemben)</p>
                                <p>Szertartás: martosi református templom</p>
                                <p>Lakodalom: <a className="dio-link" href="http://www.staryorech.sk/" target="_blank"
                                                 rel="noopener noreferrer">Vén Diófa Étterem</a></p>
                            </div>
                        </div>
                        <div className="maps">
                            <div className="map">
                                <GoogleMapWithIframe/>
                            </div>
                            <div className="map2">
                                <GoogleMapWithIframe2/>
                            </div>
                        </div>

                    </div>
                </main>
            )}
            <footer className="App-footer">
                <div className="footer-div"><p className="footer-text">© 2024 Róbert Ruska</p></div>
            </footer>
        </div>
    </div>
  );
}


export default App;
