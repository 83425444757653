const GoogleMapWithIframe2 = () => {
    return (
        <div>
            <iframe className="map-img-2"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d21396.610785767098!2d18.187536888269044!3d47.905884598170815!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476baaa3fbca2d39%3A0x4d94684af4d207f1!2zVsOpbiBEacOzZmEgw4l0dGVyZW0!5e0!3m2!1shu!2ssk!4v1714682146506!5m2!1shu!2ssk" allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    );
};

export default GoogleMapWithIframe2;