import React, { useState, useEffect } from 'react';

const Counter = ({ targetDate }) => {
    const calculateTimeLeft = () => {
        const now = new Date().getTime();
        const difference = targetDate.getTime() - now;
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((difference % (1000 * 60)) / 1000)
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    let { days, hours, minutes, seconds } = timeLeft;
    const shouldRender = days > 0 || seconds > 0 ||  minutes > 0 || hours > 0

    if (!shouldRender) {
        days = 0;
        hours = 0;
        minutes = 0;
        seconds = 0;
    }

    return (
        <div>
            <h1 className="counter-title">Esküvőnkig még hátravan:</h1>
            { <h2 className="counter-numbers">{days} nap | {hours} óra | {minutes} perc | {seconds} másodperc</h2>}
        </div>
    );
};

export default Counter;