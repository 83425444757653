import React, {useEffect, useRef, useState} from 'react';
import { Link } from 'react-scroll';

const Navbar = ({ onNavClick, content  }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="navbar-wrapper">
            <div className={`dropdown-menu ${isMenuOpen ? 'open' : ''}`}>
                <ul>
                    <li><Link onClick={() => onNavClick('home')}>Főoldal</Link></li>
                    { content === 'home' && (<li><Link to="section1" smooth={true} duration={500}>Mikor és Hol</Link></li>)}
                    <li><Link onClick={() => onNavClick('map')}>Térkép</Link></li>
                    { content === 'home' && (<li><Link to="section2" smooth={true} duration={500}>Képek</Link></li>)}
                </ul>
            </div>
            <button className="menu-button" onClick={toggleMenu}>
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
            </button>
        </div>
    );
};

export default Navbar;