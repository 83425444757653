const GoogleMapWithIframe = () => {
    return (
        <div>
            <iframe className="map-img-1"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3183.740616982463!2d18.123319441847524!3d47.85516583229199!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476bab05e98b98df%3A0x2164ee251a63cc17!2sEml%C3%A9kkapu%20a%20reform%C3%A1tus%20templom%20el%C5%91tt!5e0!3m2!1shu!2ssk!4v1714600669529!5m2!1shu!2ssk" allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
    );
};

export default GoogleMapWithIframe;